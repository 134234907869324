<template>
  <div>
    <div class="item">
      <div class="head">用户信息</div>
      <div class="main flex-between">
        <div class="main-item">
          <span class="label">微信昵称：</span>
          <span class="contain">{{detail.nickName}}</span>
        </div>
        <div class="main-item">
          <span class="label">会员等级：</span>
          <span class="contain">{{detail.level}}</span>
        </div>
        <div class="main-item flex">
          <span class="label">关联积分：</span>
          <span class="contain">{{detail.integral}}</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="head">基础信息</div>
      <div class="main flex-between">
        <div class="main-item">
          <span class="label">手机号：</span>
          <span class="contain">{{detail.phone}}</span>
        </div>
        <div class="main-item">
          <span class="label">生日：</span>
          <span class="contain">{{detail.birthday}}</span>
        </div>
        <div class="main-item flex">
          <span class="label">年龄：</span>
          <span class="contain">{{detail.age}}</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="head">交易信息</div>
      <div class="main flex-between">
        <div class="main-item">
          <span class="label">平均交易金额：</span>
          <span class="contain">{{detail.average}}</span>
        </div>
        <div class="main-item">
          <span class="label">交易总额：</span>
          <span class="contain">{{detail.total}}</span>
        </div>
        <div class="main-item flex">
          <span class="label">最后下单时间：</span>
          <span class="contain">{{detail.lastOrderTime}}</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="head">关联订单号：</div>
      <div class="main flex-between">
        <el-table
          :data="detail.order_list"
          class="tb-table"
          height="400px"
        >
          <el-table-column prop="ordersn" label="订单编号" />
          <el-table-column prop="createdAt" label="交易时间" />
          <el-table-column prop="price" label="交易金额" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'MemberDetail',
  data() {
    return {
      detail: {}
    }
  },
  computed: mapState([
    'vip'
  ]),
  mounted() {
    if (!this.$route.query.id) {
      this.$router.go(-1)
      return
    }
    this.getDetail(this.$route.query.id)
  },
  methods: {
    getDetail(id) {
      const params = {
        id
      }
      const url = 'admin/customer/detail'
      this.$https.get(url, {params}).then(res => {
        if (!res) return
        const vipitem = this.vip.find(v => v.id === res.vip_level)
        res.level = vipitem ? vipitem.vipName : ''
        res.total = 0
        res.order_list.forEach(item => {
          item.price = this.$np.divide(item.originalPrice, 100)
          res.total = this.$np.plus(res.total, item.price)
        })
        res.average = this.$np.round(this.$np.divide(res.total, res.order_list.length), 2)
        res.age = ''
        // if (res.birthday) res.age = this.$dayjs(res.birthday)
        this.detail = {...res}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  margin: 24px 24px 0;
  background-color: #fff;
  .head {
    padding: 16px 32px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #E9E9E9;
    box-sizing: border-box;
  }
  .main {
    padding: 24px 32px;
    flex-wrap: wrap;
    &-item {
      width: 33%;
      margin-top: 16px;
      .label {
        min-width: 70px;
        color: rgba(0, 0, 0, 0.85);
      }
      .contain {
        min-width: 300px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .main-item:nth-child(-n+3) {
      margin-top: 0;
    }
    .product {
      &-name {
        padding-bottom: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .divider {
      margin: 32px 0;
    }
    .no-info {
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
